import React, { useEffect, useState, useReducer, ReactNode, useCallback } from 'react'

import { Header } from '../components/organisms/Header'
import { Footer } from '../components/organisms/Footer'
import { useCookies } from 'react-cookie'
import UiContext from '../shared/context/UiContext'
import { UiReducer } from '../shared/reducers/UiReducer'
import { State } from '../shared/interface/uiInterface'

// To match NCSC website
const consentCookieName = 'google consent'
const actionedConsentCookieName = 'consent cookie'

interface IProps {
  children: ReactNode
}

export const LayoutTemplate: React.FC<IProps> = ({ children }: IProps) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    consentCookieName,
    actionedConsentCookieName,
  ])
  const cookieConsent = cookies[consentCookieName] as string
  const cookieConsentActioned = cookies[actionedConsentCookieName] as boolean
  const [showCookieWidget, setShowCookieWidget] = useState(true)

  const setGtagConsent = (consent: string) => {
    const consentValue = consent ? 'granted' : 'denied'
    gtag('consent', 'update', {
      analytics_storage: consentValue,
      ad_storage: consentValue,
    })
  }

  const domain = window.location.hostname.endsWith('.ncsc.gov.uk')
    ? '.ncsc.gov.uk'
    : window.location.hostname

  const acceptCookie = useCallback(() => {
    setCookie(consentCookieName, 'On', { path: '/', domain })
    setCookie(actionedConsentCookieName, 'true', {
      path: '/',
      domain,
    })
    window.dispatchEvent(new CustomEvent('cookiesAccepted'))
    setShowCookieWidget(false)
  }, [domain, setCookie])

  const declineCookie = useCallback(() => {
    removeCookie(consentCookieName)
    setCookie(actionedConsentCookieName, 'true', {
      path: '/',
      domain,
    })
    window.dispatchEvent(new CustomEvent('cookiesDeclined'))
    setShowCookieWidget(false)
  }, [domain, removeCookie, setCookie])

  useEffect(() => {
    if (cookieConsent !== undefined) {
      setGtagConsent(cookieConsent)
    }
  }, [cookieConsent])

  useEffect(() => {
    if (cookieConsentActioned) {
      setShowCookieWidget(false)
    }
  }, [cookieConsentActioned])

  const defaultUIContext: State = {
    isPopupOpened: false,
    isLoading: false,
    isLoadingSpinner: false,
    isStickyPopupOpened: false,
    viewExtendedChecks: false,
  }
  const [state, dispatch] = useReducer(UiReducer, defaultUIContext)
  return (
    <UiContext.Provider value={{ state, dispatch }}>
      <div>
        <div
          className={`${
            showCookieWidget ? 'show' : 'hide'
          } govuk-cookie-banner `}
          data-nosnippet
          role="region"
          aria-label="Cookies on this site"
        >
          <div className="govuk-cookie-banner__message govuk-width-container">
            <div className="govuk-grid-row">
              <div className="govuk-grid-column-three-quarters">
                <h2 className="govuk-cookie-banner__heading govuk-heading-m">
                  Cookies on this site
                </h2>

                <div className="govuk-cookie-banner__content">
                  <p className="govuk-body-s">
                    We use some essential cookies to make this website work.
                  </p>
                  <p className="govuk-body-s">
                    We’d like to set additional cookies to understand how you
                    use our website so we can improve our services.
                  </p>
                </div>
              </div>
            </div>

            <div className="govuk-button-group">
              <button
                value="accept"
                type="button"
                name="cookies"
                className="govuk-button"
                data-module="govuk-button"
                onClick={acceptCookie}
                data-cy="accept-cookies"
              >
                Accept optional cookies
              </button>
              <button
                value="reject"
                type="button"
                name="cookies"
                className="govuk-button"
                data-module="govuk-button"
                onClick={declineCookie}
                data-cy="reject-cookies"
              >
                Reject optional cookies
              </button>
              <a
                className="govuk-link"
                href="https://www.ncsc.gov.uk/section/about-this-website/cookies"
                target="_blank"
                data-cy="manage-cookies"
                rel="noreferrer"
              >
                Manage Cookies (opens in a new tab)
              </a>
            </div>
          </div>
        </div>

        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </UiContext.Provider>
  )
}

export default LayoutTemplate
